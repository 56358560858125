import Layout from "@/components/layout/Layout";
import CashClickAppDownload from "@/components/pages/home/new-home/_CashClickAppDownload";
import SeedEvents from "@/components/pages/home/new-home/_SeedEvents";
import TwitterCommunitySection from "@/components/pages/home/new-home/_TwitterCommunitySection";
import { getHomeHeroSection } from "@/config/helpers/backend_helper";
import { stage } from "@/config/helpers/web3Utiles";
import dynamic from "next/dynamic";
import { useEffect } from "react";

const AwardsPhotoSections = dynamic(
  () => import("@/components/pages/home/new-home/_AwardsPhotoSections"),
  {
    ssr: true,
  }
);
const NftMarketPlaceSection = dynamic(
  () => import("@/components/pages/home/new-home/_NftMarketPlaceSection"),
  {
    ssr: true,
  }
);
const FeatureInSection = dynamic(
  () => import("@/components/pages/home/new-home/_FeatureInSection"),
  {
    ssr: true,
  }
);
const HeroSection = dynamic(
  () => import("@/components/pages/home/new-home/_HeroSection"),
  {
    ssr: true,
  }
);
const MuseumSection = dynamic(
  () => import("@/components/pages/home/new-home/_MuseumSection"),
  {
    ssr: true,
  }
);
const PartnershipCollaborationsSection = dynamic(
  () =>
    import(
      "@/components/pages/home/new-home/_PartnershipCollaborationsSection"
    ),
  {
    ssr: true,
  }
);
const SeedTokenSection = dynamic(
  () => import("@/components/pages/home/new-home/_SeedTokenSection"),
  {
    ssr: true,
  }
);
const ShowcaseNftMarketplaceSection = dynamic(
  () =>
    import("@/components/pages/home/new-home/_ShowcaseNftMarketplaceSection"),
  {
    ssr: true,
  }
);
const TokenMetrics = dynamic(
  () => import("@/components/pages/home/new-home/_TokenMetricsSection"),
  {
    ssr: true,
  }
);
const TrustedPartnersSection = dynamic(
  () => import("@/components/pages/home/new-home/_TrustedPartnersSection"),
  {
    ssr: true,
  }
);

export async function getServerSideProps() {
  var heroData;
  try {
    heroData = await getHomeHeroSection();
  } catch (error) {
    console.log(error);
    heroData = await getHomeHeroSection();
  }

  if (!heroData) {
    return {
      props: {
        heroData: null,
      },
    };
  }

  return {
    props: {
      heroData: heroData,
    },
  };
}

function HomeSeedPhoto({ heroData }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("twitter");
    }
  }, []);
  return (
    <Layout>
      <div className="home">
        <div className="section-nw home__hero">
          <HeroSection />
        </div>

        <div className="section-nw ">
          <NftMarketPlaceSection heroData={heroData} />
        </div>

        <div className="section-nw home__cashClick">
          <CashClickAppDownload />
        </div>

        <div className="section-nw home__seedToken">
          <SeedTokenSection />
        </div>

        <div className="section home__seedToken">
          <TokenMetrics />
        </div>

        {stage === "stage1" ? (
          <div className="section home__twitterCommunity">
            <TwitterCommunitySection
              title={"Twitter Community: Engage, Earn, Empower"}
              link={"/twitter-farm"}
            />
          </div>
        ) : null}

        <div className="section-dark home__awardsPtotos">
          <AwardsPhotoSections />
        </div>

        <div className="section-nw home__featureIn">
          <SeedEvents />
        </div>

        <div className="section-nw home__cashClick">
          <CashClickAppDownload subTitle="Snap, Share, and Earn with Friends!" />
        </div>

        <div className="section-nw home__featureIn">
          <FeatureInSection />
        </div>

        {/* {process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
        process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost") ? ( */}
        <div className="section-nw home__meuseum">
          <MuseumSection />
        </div>
        {/* ) : null} */}

        <div className="section-nw home__featureIn">
          <PartnershipCollaborationsSection />
        </div>

        <div className="section-nw home__featureIn">
          <TrustedPartnersSection />
        </div>

        <div className="section-nw marketplace">
          <ShowcaseNftMarketplaceSection />
        </div>
      </div>
    </Layout>
  );
}

export default HomeSeedPhoto;
