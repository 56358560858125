// import PwaApp from "@/assets/images/icon-PWA-App.png";
import googlePlayImg from "@/assets/images/home/cashClickApp/share_google_play_logo.png";
import seedIcon from "@/assets/images/icons/icon-seedlogo-white.svg";
import seedIconDark from "@/assets/images/home/cashClickApp/icon-seedlogo-dark.svg";
import seedPhoneImg from "@/assets/images/home/cashClickApp/cashclick-app-mobile-seed.photo.webp";
import dynamic from "next/dynamic";
// import Modal from "@/components/common/modal";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

import CashclickLight from "@/assets/images/home/cashClickApp/CashclickLight.png";
import CashclickDark from "@/assets/images/home/cashClickApp/CashclickDark.png";
import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Telegram from "@/assets/images/icons/Social-media/Telegram.svg";
import { useMediaQuery } from "react-responsive";
import CashClickButtons from "./components/cashClick/CashClickButtons";

const Modal = dynamic(() => import("@/components/common/modal"), {
  ssr: false,
});

function CashClickAppDownload({
  title = "Cashclick App",
  subTitle = "Earn SEEDx with Every Click",
}) {
  const [activeModal, setActiveModal] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const [modal_pwaApp, setModal_pwaApp] = useState(false);
  function tog_pwaApp() {
    setModal_pwaApp(!modal_pwaApp);
    setActiveModal(1);
  }
  return (
    <>
      <div className="center">
        <div>
          <div className="home__cashClick__haed">
            <div className="home__cashClick__title">
              <div className="home__cashClick__tablet burst">
                <BurstPuckerImageTitles />
              </div>
              {title}
            </div>
            <div>
              {" "}
              <div className="some-icon">
                <Image
                  src={CashclickLight}
                  alt="CashclickLight"
                  width={51}
                  height={47}
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="some-icon-dark">
                <Image
                  src={CashclickDark}
                  alt="CashclickDark"
                  width={51}
                  height={47}
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="home__cashClick__text1">{subTitle}</div>
      </div>
      <div className="home__cashClick__box">
        <div className="description__download_section center">
          {!isMobile ? (
            <div className="home__cashClick__details ">
              <div className="description__download_detail">
                <div className="home__cashClick__description">
                  <div className="home__cashClick__text">
                    Introducing Cash Click
                  </div>
                  <div className="home__cashClick__text2">
                    our innovative app revolutionizing the way you earn online.
                    Simply browse, engage, and earn rewards in cryptocurrency –
                    it&apos;s that easy!
                  </div>
                </div>
                <div className="home__cashClick__description">
                  <div className="home__cashClick__text">
                    User-Friendly Interface
                  </div>
                  <div className="home__cashClick__text2">
                    Navigate with ease and track your earnings in real-time.
                    Earn cryptocurrency seamlessly as you browse and click on
                    content.
                  </div>
                </div>
              </div>
              <CashClickButtons />
            </div>
          ) : null}

          <div className="home__cashClick__photo">
            <Image
              src={seedPhoneImg}
              loading="lazy"
              alt="cashclick-app-mobile-seed.photo"
              width={516}
              height={596}
              quality={100}
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className="home__cashClick__box-mobile ">
          <div className="home__cashClick__section-mobile">
            <div className="home__cashClick__text1-mobile">{subTitle}</div>
            <div className="home__cashClick__section-text-mobile">
              <div className="home__cashClick__description">
                <div className="home__cashClick__text">
                  Introducing Cash Click
                </div>
                <div className="home__cashClick__text2">
                  our innovative app revolutionizing the way you earn online.
                  Simply browse, engage, and earn rewards in cryptocurrency –
                  it&apos;s that easy!
                </div>
              </div>
              <div className="home__cashClick__description">
                <div className="home__cashClick__text">
                  User-Friendly Interface
                </div>
                <div className="home__cashClick__text2">
                  Navigate with ease and track your earnings in real-time. Earn
                  cryptocurrency seamlessly as you browse and click on content.
                </div>
              </div>
            </div>

            <CashClickButtons />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CashClickAppDownload;
