import default_nft_img from "@/assets/images/default_image_01.png";
import arrowRight from "@/assets/images/home/events/arrow-right.svg";
import calendarDark from "@/assets/images/home/events/calendar-dark.svg";
import calendarLight from "@/assets/images/home/events/calendar-light.svg";
import speakerDark from "@/assets/images/home/events/microphone-dark.svg";
import speakerLight from "@/assets/images/home/events/microphone-light.svg";
import Image from "next/image";
import Link from "next/link";

function EventCardInnerDetails({
  myUser,
  item,
  component,
  mycollection,
  state,
  FirstId,
  indexCard,
}) {
  return (
    <>
      <div key={item?.id} className="card2 event">
        <div
          // href={`/nfts/details/${item.Nft.Slug}`}
          className="item_card card2__box"
          key={item?.id}
        >
          {/* <Link
          href={`/nfts/details/${item.Nft.Slug}`}
          className="item_card card2__box"
          key={item.Nft.Id}
          aria-label="nfts-details"
          data-link={`/nfts/details/${item.Nft.Slug}`}
        >*/}
          <div className={`card2__preview `}>
            {item?.meta["youtube-url"] !== null ? (
              <iframe
                loading="lazy"
                src={`https://www.youtube.com/embed/${item?.meta["youtube-embed-id-link"]}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            ) : (
              <Image src={default_nft_img} alt="Card preview" />
            )}
          </div>
          <div className="card2__link">
            <div className="card2__body">
              <div
                className="card2__title"
                dangerouslySetInnerHTML={{
                  __html: item?.title?.rendered,
                }}
              >
                {/* {item.Nft.Id === FirstId ? <div>Image</div> : null} */}
              </div>
              <div className="event__row">
                <Image
                  className="some-icon"
                  src={speakerLight}
                  width={24}
                  height={24}
                  alt="speaker-light"
                />
                <Image
                  className="some-icon-dark"
                  src={speakerDark}
                  width={24}
                  height={24}
                  alt="speaker-dark"
                />
                <div className="row_texts">
                  <span className="row_title">Speakers:</span>

                  <div className="tooltip1">
                    <div className="tooltipText1">{item?.meta?.speakers}</div>
                    <span className="row_value">{item?.meta?.speakers}</span>
                  </div>
                </div>
              </div>
              <div className="event__row">
                {" "}
                <Image
                  className="some-icon"
                  src={calendarLight}
                  width={24}
                  height={24}
                  alt="calendar-light"
                />
                <Image
                  className="some-icon-dark"
                  src={calendarDark}
                  width={24}
                  height={24}
                  alt="calendar-dark"
                />
                <div className="row_texts">
                  <span className="row_title">Schedule:</span>
                  {item?.meta?.schedule}
                </div>
              </div>
              <Link
                href={item?.meta["youtube-url"]}
                target="_blank"
                className="event__row more_link"
              >
                <Image
                  className="more-detail"
                  src={arrowRight}
                  width={24}
                  height={24}
                  alt="more-detail"
                />
                more details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventCardInnerDetails;
