import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import { createSelectorEvents } from "@/config/helpers/createReduxSelectors";
import { eventsData } from "@/store/actions";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import EventCardInnerDetails from "@/components/common/cards/eventsCards/eventInnerDetails";
import { isEmpty } from "lodash";

function SeedEvents() {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState([]);

  const [swiper, setSwiper] = useState(null);
  const swipertop = useRef();
  const swiperbot = useRef();

  const { errorMsg, loading, error, success, events } =
    useSelector(createSelectorEvents);

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventsData());
    }, 500);
  }, []);

  useEffect(() => {
    if (!isEmpty(events)) {
      setEventData(events);
    }
  }, [events, eventData]);

  const handleMouseEnter = () => {
    swipertop?.current?.swiper?.autoplay?.stop();
    swiperbot?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swipertop?.current?.swiper?.autoplay?.start();
    swiperbot?.current?.swiper?.autoplay?.start();
  };
  return (
    <>
      <div className="hot__center">
        <div className="hot__wrapper">
          <div className="main__row__title center">
            <div className="main__title ">
              <div className="hot__title h3 burst">
                <BurstPuckerImageTitles />
                Events
              </div>
            </div>
            <div className="main__btn">
              <Link
                className="button-stroke"
                href="https://blog.seed.photo/events/"
                target="_blank"
              >
                Explore More
                <i className="icon-chevron_up"></i>
              </Link>
            </div>
          </div>

          <div
            className={`recently__center ${
              eventData?.length > 2 ? "" : "center"
            }`}
          >
            <div
              className="recently__inner"
              style={{
                paddingBottom: "40px",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                loop={false}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                speed={700}
                observer={true}
                observeParents={true}
                // navigation={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  768: {
                    slidesPerView: `auto`,
                    slidesPerGroup: 1,
                  },
                  1340: {
                    slidesPerView: `auto`,
                    slidesPerGroup: 1,
                  },
                }}
                ref={swipertop}
                className="seed_carousel is-buttom-center-scretch"
              >
                {eventData &&
                  eventData.slice(0, 6)?.map((item, index) => (
                    <SwiperSlide key={item?.id}>
                      <EventCardInnerDetails
                        item={item}
                        helperClass={""}
                        isSlide={true}
                        key={item?.id}
                        index={index}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeedEvents;
