import Image from "next/image";
import Link from "next/link";
import React from "react";
import Telegram from "@/assets/images/icons/Social-media/Telegram.svg";

function CashClickButtons() {
  return (
    <div className="home__cashClick__btns">
      <Link
        className="home__cashClick__button"
        href="https://t.me/Cashclickappbot"
        target="_blank"
      >
        <div className="">
          <Image
            width={31}
            height={30}
            loading="lazy"
            src={Telegram}
            alt="telegram logo"
            style={{ objectFit: "contain" }}
          />
        </div>

        <div className="btn_join">join in</div>

        <span className="btn_title">Telegram</span>
      </Link>
      {/* <Image loading="lazy" src={googlePlayImg} alt="seed logo" /> */}
      <Link
        className="home__cashClick__button"
        href={"https://cashclick.app/"}
        target="_blank"
      >
        cashclick.app
      </Link>
    </div>
  );
}

export default CashClickButtons;
